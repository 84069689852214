export function serviceName(service: string) {
  switch (service.toUpperCase()) {
    case 'QUESTIONBASE':
      return 'Question Base';
    case 'INTERCOM':
      return 'Intercom';
    case 'NOTION':
      return 'Notion';
    case 'GOOGLE_DRIVE':
      return 'Google Drive';
    case 'ZENDESK':
      return 'Zendesk';
    case 'FRESHDESK':
      return 'Freshdesk';
    case 'CONFLUENCE':
      return 'Confluence';
    case 'GITHUB':
      return 'Github';
    case 'DROPBOX':
      return 'Dropbox';
    default:
      throw new Error(`Unknown service: ${service}`);
  }
}
