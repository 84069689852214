import { Box, Heading, ListItem, Text } from '@chakra-ui/react';

export const ListEl = ({
  children,
  title,
  opacity,
  subtitle,
  mt,
}: {
  children: React.ReactNode;
  title: string;
  opacity?: number;
  subtitle?: string;
  mt?: number;
}) => (
  <ListItem
    letterSpacing={-0.02}
    color="rgba(19, 32, 45, 1)"
    fontSize="20px"
    fontWeight={700}
    lineHeight={1}
    py={4}
    mt={mt}
    opacity={opacity}
  >
    <Heading
      as="h2"
      letterSpacing={-0.02}
      color="rgba(19, 32, 45, 1)"
      fontSize="20px"
      fontWeight={700}
      lineHeight={1}
    >
      {title}
    </Heading>
    <Text mt={2} fontWeight={500} fontSize="13px" color="gray.600">
      {subtitle}
    </Text>
    <Box
      fontSize="13px"
      lineHeight="16px"
      fontWeight={400}
      letterSpacing={-0.02}
    >
      {children}
    </Box>
  </ListItem>
);
