import { useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  OrderedList,
  Select,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  DirectoryChannelsEndpoint,
  DirectorySetupEndpoint,
} from '@core/types/types.endpoint.directory';

import { AddBot } from 'src/App/App.AddBot';
import { ListEl } from 'src/App/App.ListEl';
import { SettingsModalIntegrations } from 'src/Settings/components/SettingsModalIntegrations';
import { UserField } from 'src/shared/components/UserField/UserField';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryRefresher } from 'src/shared/hooks/shared.hook.useDirectoryRefresher';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import {
  useDirectoryMe,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppSetup() {
  const refresh = useDirectoryRefresher(
    {
      team: true,
      channels: true,
      users: true,
      me: true,
    },
    false,
    0,
  );
  const me = useDirectoryMe();
  const team = useDirectoryTeam();
  const isInstalled = !!team.slack_bot_user_id;
  const canAddChannels = !!team.slack_scope?.includes('channels:join');
  const canImport = useFlag('import');
  const proModal = useDisclosure();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([me.id]);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState('0');

  const { data: channels, loading } = useApi<DirectoryChannelsEndpoint>(
    '/directory/channels',
    {},
    isInstalled && canAddChannels,
  );

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<DirectorySetupEndpoint>('/directory/setup', {
      channelIds: selectedChannels,
      expertIds: selectedUsers,
      importDays: parseInt(selectedDays, 10),
    });

    if (res) {
      await refresh.submit();
    }
  });

  return (
    <>
      {!canImport && (
        <SettingsModalIntegrations
          isOpen={proModal.isOpen}
          feature="import"
          onClose={proModal.onClose}
        />
      )}
      <OrderedList>
        <ListEl
          title="Add the bot to your Slack Workspace"
          subtitle="This will allow the bot to join."
        >
          <Spacer height={6} />
          {isInstalled ? (
            <Flex alignItems="center" pb={10}>
              <Icon
                as={IoIosCheckmark}
                h={6}
                background="rgba(176, 248, 120, 1)"
                borderRadius={12}
                w={6}
                color="rgba(27, 27, 27, 1)"
              />
              <Text ml={3} color="rgba(27, 27, 27, 1)">
                Bot successfully installed!
              </Text>
            </Flex>
          ) : (
            <AddBot />
          )}
        </ListEl>
        <ListEl
          opacity={isInstalled ? 1 : 0.3}
          title="Who keeps documentation up-to-date?"
          subtitle="Select team members who typically respond to questions in your team:"
        >
          <Spacer height={5} />
          <UserField
            isDisabled={!isInstalled}
            placeholder="Select users"
            excludeDeleted
            selectedIds={selectedUsers}
            setSelectedIds={setSelectedUsers}
          />
          <Spacer height={5} />
        </ListEl>
        <ListEl
          opacity={isInstalled ? 1 : 0.3}
          title="Select channels for the bot to join"
          subtitle="Capture knowledge and automatically respond to
      questions in these channels:"
        >
          {loading && <Spinner />}
          {channels?.map((c, i) => (
            <Box key={c.id} mt={i === 0 ? 6 : 3}>
              <Checkbox
                isChecked={selectedChannels.includes(c.id)}
                onChange={(e) => {
                  // If the option is already selected, remove it from the array
                  // Otherwise, add it to the array
                  setSelectedChannels(
                    e.target.checked
                      ? [...selectedChannels, c.id]
                      : selectedChannels.filter((id) => id !== c.id),
                  );
                }}
              >
                #{c.name}
              </Checkbox>
            </Box>
          ))}
        </ListEl>
        {false && (
          <ListEl
            mt={10}
            opacity={!!selectedChannels.length ? 1 : 0.3}
            title="Do you want to import channel history into Question Base?"
            subtitle="Quickly get started by training the bot on previous conversations."
          >
            <Select
              mt={5}
              maxW="400px"
              value={selectedDays}
              onChange={(e) => {
                if (
                  !canImport &&
                  e.target.value !== '0' &&
                  e.target.value !== '14'
                ) {
                  proModal.onOpen();
                } else {
                  setSelectedDays(e.target.value);
                }
              }}
            >
              <option value="0">No thanks</option>
              <option value="14">14 days (Recommended start)</option>
              <option value="30">30 days{!canImport && ' (Pro)'}</option>
              <option value="90">90 days{!canImport && ' (Pro)'}</option>
              <option value="180">180 days{!canImport && ' (Pro)'}</option>
              <option value="365">365 days{!canImport && ' (Pro)'}</option>
            </Select>
            <Text mt={2} fontWeight={500} fontSize="13px" color="gray.600">
              * The AI will dig out shared knowledge from previous
              conversations. It will import them as “Unverified” answers that
              you can quickly verify or dismiss.
            </Text>
          </ListEl>
        )}
      </OrderedList>
      <Button
        mt={4}
        colorScheme="action"
        ml={4}
        onClick={submit.submit}
        isLoading={submit.isSubmitting}
        isDisabled={
          !selectedUsers.length || !isInstalled || !selectedChannels.length
        }
      >
        Create Question Base
      </Button>
    </>
  );
}
