import { BsFillLightningChargeFill } from 'react-icons/bs';

import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  IntegrationAuthEndpoint,
  IntegrationUsageEndpoint,
} from '@core/types/types.endpoint.integration';
import {
  CarbonConnect,
  EmbeddingGenerators,
  IntegrationName,
} from 'carbon-connect';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsIntegrationsOld() {
  const hasIntegrations = useFlag('integrations');

  const { data } = useApi<IntegrationUsageEndpoint>(
    '/integration/usage',
    {},
    hasIntegrations,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const tokenFetcher = async () => {
    const [err, res] = await callApi<IntegrationAuthEndpoint>(
      '/integration/auth',
      {},
    );

    if (err || !res) {
      throw new Error('Failed to fetch token');
    }

    return res;
  };

  if (!hasIntegrations || !data?.connections.length) {
    return null;
  }

  return (
    <Box opacity={0.6}>
      <Heading size="md" mt={10}>
        Old Integrations
      </Heading>

      <Text mt={2}>
        Will be removed 31st of January due to Carbon.ai being sold to
        Perplexity
      </Text>
      {!!data?.connections.length &&
        data?.connections.map(({ icon, service }, i) => (
          <Text mt={2} key={i} display="flex" alignItems="center" fontSize="sm">
            <Image src={icon} w={5} h={5} mr={2} />
            {service}
          </Text>
        ))}

      <Button
        leftIcon={<Icon as={BsFillLightningChargeFill} />}
        colorScheme="action"
        variant="outline"
        mt={3}
        size="xs"
        onClick={onOpen}
      >
        Manage Old Integrations
      </Button>
      <CarbonConnect
        orgName="Question Base"
        brandIcon="/assets/logoimage.png"
        embeddingModel={EmbeddingGenerators.COHERE_MULTILINGUAL_V3}
        tokenFetcher={tokenFetcher}
        setOpen={(_isOpen) => {
          if (_isOpen) {
            onOpen();
          } else {
            onClose();
          }
        }}
        open={isOpen}
        environment="PRODUCTION"
        enabledIntegrations={[
          {
            id: IntegrationName.NOTION,
            syncFilesOnConnection: false,
            enableAutoSync: true,
            incrementalSync: true,
          },
          {
            id: IntegrationName.ZENDESK,
            syncFilesOnConnection: false,
            enableAutoSync: true,
            incrementalSync: true,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.FRESHDESK,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.INTERCOM,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GURU,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.CONFLUENCE,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GOOGLE_DRIVE,
            syncFilesOnConnection: false,
            enableAutoSync: true,
            incrementalSync: true,
          },
          {
            id: IntegrationName.DROPBOX,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.BOX,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.SALESFORCE,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
          {
            id: IntegrationName.GITHUB,
            syncFilesOnConnection: false,
            enableAutoSync: false,
            useCarbonFilePicker: true,
          },
        ]}
        chunkSize={1500}
        overlapSize={20}
      ></CarbonConnect>
    </Box>
  );
}
