import { useState } from 'react';
import { LuExternalLink } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';

import { Button, Heading, Icon, Text } from '@chakra-ui/react';
import { BillingLinkEndpoint } from '@core/types/types.endpoint.billing';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useIsPro } from 'src/shared/hooks/shared.hook.useIsPro';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function SettingsAccountType() {
  const { pathname } = useLocation();
  // Determine if we are running locally
  const team = useDirectoryTeam();
  const [loadBilling, setLoadBilling] = useState(false);

  const { data, loading } = useApi<BillingLinkEndpoint>(
    '/billing/link',
    {},
    loadBilling,
  );

  const isPro = useIsPro();

  const local = window.location.hostname.startsWith('localhost');

  return (
    <>
      <Heading size="md">Account status</Heading>
      <Text mt={2} mb={2}>
        Your team is on the <b>{isPro ? 'Pro' : 'Free'} plan</b>
      </Text>
      {(!team.stripe_customer_id || !isPro) && (
        <Button
          mr={5}
          as="a"
          href={`/v1/billing/pay?teamId=${team.id}&redirectUri=${pathname}${local ? '&local=1' : ''}`}
          colorScheme="action"
          variant="link"
        >
          Upgrade to Pro
        </Button>
      )}
      {!!team.stripe_customer_id && !data && (
        <Button
          variant="link"
          colorScheme="action"
          onClick={() => {
            setLoadBilling(true);
          }}
          isDisabled={loading}
        >
          {loading ? 'Generating link...' : 'Open billing'}
        </Button>
      )}
      {data && (
        <Button
          as="a"
          target="_blank"
          href={data.url}
          variant="link"
          colorScheme="action"
          rightIcon={<Icon w={5} h={5} as={LuExternalLink} />}
        >
          Stripe Billing Portal
        </Button>
      )}
    </>
  );
}
