import { useInterval } from '@chakra-ui/react';
import { DirectoryRefreshEndpoint } from '@core/types/types.endpoint.directory';

import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function useDirectoryRefresher(
  options: DirectoryRefreshEndpoint['body'],
  condition: boolean,
  interval = 5000,
) {
  const updater = useDirectoryUpdater();

  const refresh = useSubmitter(async () => {
    const [, res] = await callApi<DirectoryRefreshEndpoint>(
      '/directory/refresh',
      options,
    );

    updater(res);
  });

  // Refresh every 5 seconds if syncing
  useInterval(() => {
    if (condition) {
      refresh.submit();
    }
  }, interval);

  return refresh;
}
