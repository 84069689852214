import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { DirectoryAnalyticsEndpoint } from '@core/types/types.endpoint.directory';

import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsModalIntegrations({
  isOpen,
  onClose,
  feature,
}: {
  isOpen: boolean;
  onClose: () => void;
  feature: 'integrations' | 'notion' | 'import';
}) {
  useEffect(() => {
    if (isOpen) {
      callApi<DirectoryAnalyticsEndpoint>('/directory/analytics', {
        event: 'Viewed Pro',
        pro_type: feature,
      });
    }
  }, [isOpen]);

  const team = useDirectoryTeam();
  const { pathname } = useLocation();
  // Determine if we are running locally
  const local = window.location.hostname.startsWith('localhost');

  let valueProp: ReactNode = null;

  let learnMoreUrl = '';
  if (feature === 'integrations') {
    learnMoreUrl = 'https://www.questionbase.com/integrations';
    valueProp = (
      <>
        <Text fontWeight={600}>
          Train the bot to answer using your company documentation:
        </Text>
        <Text mt={2}>• Notion</Text>
        <Text>• Confluence</Text>
        <Text>• Google Drive</Text>
        <Text>• Box</Text>
        <Text>• Dropbox</Text>
        <Text>• Guru</Text>
        <Text>• Zendesk</Text>
        <Text>• Intercom</Text>
        <Text>• Freshdesk</Text>
        <Text>• Salesforce</Text>
      </>
    );
  } else if (feature === 'notion') {
    learnMoreUrl =
      'https://www.questionbase.com/docs/export-to-notion-slack-faq';
    valueProp = (
      <>
        <Text fontWeight={600}>
          Export your Question Base to a page in Notion:
        </Text>
        <Text mt={2}>• Export Slack knowledge to a Notion page</Text>
        <Text>• Continiously enrich it</Text>
        <Text>• Keep it up-to-date with the duplicate check</Text>
      </>
    );
  } else if (feature === 'import') {
    learnMoreUrl =
      'https://www.questionbase.com/docs/train-an-answering-bot-on-your-slack-history';
    valueProp = (
      <>
        <Text fontWeight={600}>
          Import Slack channel history into Question Base:
        </Text>

        <Text mt={2}>• Capture valuable knowledge from past conversations</Text>
        <Text>• Turn Slack into an FAQ</Text>
        <Text>• Extract up to 365 Days of channel history</Text>
      </>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pro feature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {valueProp}
          <Button
            variant="link"
            as="a"
            mt={2}
            href={learnMoreUrl}
            target="_blank"
            color="blue"
          >
            See this feature in action
          </Button>
          <Divider mt={6} />
          <Button
            mt={6}
            as="a"
            href={`/v1/billing/pay?teamId=${team.id}&redirectUri=${pathname}${local ? '&local=1' : ''}`}
            colorScheme="action"
          >
            Upgrade to Pro
          </Button>
          <Text mt={6}>
            Want a demo?{' '}
            <Button
              as="a"
              href="https://cal.com/team/questionbase/call"
              color="blue"
              variant="link"
              target="_blank"
            >
              Book a call
            </Button>
          </Text>
          <Text mt={2}>
            Have a question?{' '}
            <Button
              as="a"
              target="_blank"
              href="mailto:help@questionbase.com"
              color="blue"
              variant="link"
            >
              Email us
            </Button>
          </Text>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
